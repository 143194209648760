import styles from "./ProjectBoxVertical.module.scss";
import React from "react";

const ProjectBoxVertical = ({ node }) => (
  <div className={styles.projectBox} key={node.id}>
    <div>
      <img src={node.image.publicURL} alt={node.title} />
    </div>

    <div className={styles.data}>
      <h2>{node.title}</h2>

      <p>{node.description}</p>
    </div>
  </div>
);

export default ProjectBoxVertical;
